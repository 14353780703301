import React from 'react';
import {motion} from 'framer-motion';
import {graphql, Link} from 'gatsby';
import {SectionHeader} from '../components/Headings';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {pageVariants, pageTransition} from 'components/Transitions';
import {TwoColBlock, SingleContentBlock} from 'components/ContentBlocks';
import QuoteBlock from 'components/QuoteBlock';
import Card from 'components/Card';
import Layout from 'components/Layout';
import {MainHeroHeader} from 'components/Headings';
import {FaAngleDoubleRight} from 'react-icons/fa';
import PageBuilder from '../components/page-builder/PageBuilder';

import 'styles/App.scss';

function LandingPage({data}) {
  const {
    seomatic,
    heroHeadingTopLevel,
    heroHeadingHighlight,
    heroHeadingHighlightColour,
    heroHeadingBottomLevel,
    description,
    relatedCaseStudies,
    relatedContentLabel,
    relatedContentEntry,
    blocks,
  } = data.craftLandingPagesLandingPageEntry;

  const {uri: relatedContentUri} = relatedContentEntry ?? {};
  const hasRelatedCaseStudies = relatedCaseStudies?.length > 0;
  const showRelatedContentSection =
    relatedContentLabel || hasRelatedCaseStudies;

  return (
    <Layout hasContact seomatic={seomatic}>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}>
        <div className="font-display bg-white">
          <div className="relative max-h-screen pl-6 pr-6 text-white background bg-gradient-to-r from-black to-dark font-display">
            <div className="w-full max-w-screen-xl pb-8 m-auto pt-28 md:pt-44 md:pb-12">
              <MainHeroHeader
                headingTopLevel={heroHeadingTopLevel}
                headingHighlightLevel={heroHeadingHighlight}
                headingHighlightColour={heroHeadingHighlightColour}
                headingBottomLevel={heroHeadingBottomLevel}
              />
              <div
                className="max-w-4xl pt-8 leading-normal prose-lg sm:prose-2xl md:prose-3xl"
                dangerouslySetInnerHTML={{__html: description}}
              />
            </div>
          </div>
          <div className="pb-12 bg-white md:pb-16 lg:pb-28">
            {blocks?.length > 0 && <PageBuilder blocks={blocks} />}
          </div>

          {showRelatedContentSection && (
            <div className="px-6">
            <div className="max-w-screen-xl m-auto w-full border-t border-gray-500 border-opacity-20 mt-6 md:mt-0 pt-8">
              {relatedContentLabel && (
                <Link to={relatedContentUri ?? '/our-work'}>
                  <h3 className="font-bold bg-gradient-to-r from-orange to-pink text-transparent bg-clip-text inline-block underline mb-6">
                    {`${relatedContentLabel} `}
                    <FaAngleDoubleRight
                      color="#EA4090"
                      className="inline bg-gradient-to-r from-orange to-pink text-transparent bg-clip-text"
                      size="20px"
                    />
                  </h3>
                </Link>
              )}
              {hasRelatedCaseStudies && (
                <div className="max-w-screen-xl m-auto w-full text-black grid gap-y-10 justify-items-center lg:grid-cols-2 lg:gap-x-12 lg:gap-y-32 xl:gap-x-24 pb-12 md:pb-16 lg:pb-48">
                  {relatedCaseStudies.map(
                    ({
                      id,
                      heading,
                      shortDescription,
                      heroImage: [heroImage],
                      heroImageAlt,
                      caseStudy: [
                        {
                          clientName,
                          tags,
                          uri,
                          heroImage: [caseStudyHeroImage] = [],
                          heroImageAlt: caseStudyHeroImageAlt,
                        } = {},
                      ],
                    }) => (
                      <Card
                        key={`related-case-study-${id}`}
                        heading={heading}
                        client={clientName}
                        blurb={shortDescription}
                        tags={tags}
                        path={uri}
                        heroImage={heroImage ?? caseStudyHeroImage}
                        alt={heroImageAlt ?? caseStudyHeroImageAlt}
                      />
                    )
                  )}
                  </div>
              )}
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </Layout>
  );
}

export const query = graphql`
  fragment CaseStudyImage on Craft_assets_Asset {
    localFile {
      childImageSharp {
        gatsbyImageData(width: 1024, height: 768, quality: 80)
      }
    }
  }

  query ($id: String) {
    craftLandingPagesLandingPageEntry(id: {eq: $id}) {
      seomatic {
        ...SeomaticFields
      }
      heroHeadingTopLevel
      heroHeadingHighlight
      heroHeadingHighlightColour
      heroHeadingBottomLevel
      description
      intro
      workingWithUsHeading
      workingWithUsBody
      callToActionButtonLabel
      callToActionMailTo
      callToActionEntry {
        uri
      }
      videoUrl
      heroImage {
        ... on Craft_assets_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1024, height: 768, quality: 80)
            }
          }
        }
      }
      heroBannerImage {
        ... on Craft_assets_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      relatedContentLabel
      relatedContentEntry {
        uri
      }
      relatedCaseStudies {
        ... on Craft_relatedCaseStudies_caseStudies_BlockType {
          id
          heading
          shortDescription
          heroImageAlt
          heroImage {
            ...CaseStudyImage
          }
          caseStudy {
            uri
            ... on Craft_caseStudies_caseStudy_Entry {
              clientName
              heroImageAlt
              heroImage {
                ...CaseStudyImage
              }
              tags {
                id
                title
              }
            }
          }
        }
      }

      #####################################
      # START OF BLOCKS
      #####################################
      blocks {
        ... on Craft_blocks_article_BlockType {
          typeHandle
          heroBannerImage {
            ... on Craft_assets_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1024, height: 768, quality: 80)
                  id
                }
              }
            }
          }
          workingWithUsHeading
          workingWithUsBody
        }
        ...on Craft_blocks_cta_BlockType {
          typeHandle
          backgroundColour
          callToActionButtonLabel
          callToActionMailTo
          callToActionEntry {
            uri
            title
          }
        }
        ... on Craft_blocks_caseStudies_BlockType {
          typeHandle
          heading
          caseStudies {
            ... on Craft_caseStudies_BlockType {
              id
              heading
              highlightedHeading
              shortDescription
              readMoreLabel
              heroImage {
                ... on Craft_assets_Asset {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800, height: 600, quality: 80)
                    }
                  }
                }
              }
              caseStudy {
                ... on Craft_caseStudies_caseStudy_Entry {
                  uri
                  tags {
                    id
                    title
                  }
                }
              }
            }
          }
        }
        ... on Craft_blocks_fullWidthScreenshot_BlockType {
          typeHandle
          screenshot {
            ... on Craft_assets_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1280
                    height: 600
                    quality: 80
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
          screenshotAlt
        }
        ... on Craft_blocks_keypoints_BlockType {
          typeHandle
          heading
          backgroundColour
          callToActionButtonLabel
          callToActionEntry {
            uri
          }
          callToActionMailTo
          keypoints {
            ... on Craft_keypoints_BlockType {
              id
              keypointTitle
              keypointContent
            }
          }
        }
        ... on Craft_blocks_largeVideo_BlockType {
          typeHandle
          heading
          description
          embedUrl
          ctaText
          callToActionUrl
          placeholderImage {
            url
          }
          placeholderImageAlt
        }
        ... on Craft_blocks_logos_BlockType {
          typeHandle
          heading
          backgroundColour
          logos {
            ... on Craft_logos_BlockType {
              id
              partnerName
              partnerLink
              logo {
                ... on Craft_assets_Asset {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 200, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
        ... on Craft_blocks_process_BlockType {
          typeHandle
          heading
          process {
            ... on Craft_process_BlockType {
              id
              heading
              description
              image {
                ... on Craft_assets_Asset {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 60, layout: CONSTRAINED)
                    }
                  }
                }
              }
              entry {
                id
                uri
              }
            }
          }
        }
        ... on Craft_blocks_quote_BlockType {
          typeHandle
          speechMarksColour
          text
          caption
        }
        ... on Craft_blocks_resources_BlockType {
          typeHandle
          heading
          resources {
            ... on Craft_resources_BlockType {
              id
              heading
              shortDescription
              heroImage {
                ... on Craft_assets_Asset {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 460, height: 210, quality: 80)
                    }
                  }
                }
              }
              resource {
                id
                uri
              }
            }
          }
        }
        ... on Craft_blocks_testimonials_BlockType {
          typeHandle
          heading
        }
        ... on Craft_blocks_textAndImageVideo_BlockType {
          typeHandle
          text
          heroImageAlt
          embedUrl
          backgroundColour
          heroImage {
            ... on Craft_assets_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1024, height: 768, quality: 80)
                  id
                }
              }
            }
          }
        }
        ... on Craft_blocks_whatWeDo_BlockType {
          typeHandle
          heading
          description
          backgroundColour
          underlineColour
          ourExpertise {
            ... on Craft_ourExpertise_BlockType {
              heading
              description
              entry {
                id
                uri
              }
            }
          }
        }
        ... on Craft_blocks_wysiwyg_BlockType {
          typeHandle
          wysiwygContent
          backgroundColour
        }
      }

      # END OF BLOCKS
    }
  }
`;

export default LandingPage;
