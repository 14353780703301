import React, {useCallback} from 'react';
import {motion, useAnimation} from 'framer-motion';
import {Link} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import {ButtonPrimary} from '../components/Button';

const Card = ({heading, client, blurb, date, tags, alt, path, heroImage}) => {
  const control = useAnimation();

  const onHoverStart = useCallback(() => {
    control.start({
      y: -5,
    });
  }, [control]);

  const onHoverEnd = useCallback(() => {
    control.start({
      y: 0,
    });
  }, [control]);

  return (
    <Link to={`/${path}`} className="block font-display">
      <motion.article onHoverStart={onHoverStart} onHoverEnd={onHoverEnd}>
        <div className="text-black">
          <div className="overflow-hidden rounded">
            <GatsbyImage
              image={getImage(heroImage?.localFile)}
              alt={alt ?? heading}
              className="transition duration-300 transform rounded-md hover:scale-105"
            />
          </div>

          <motion.div
            animate={control}
            transition={{duration: 0.2}}
            className="bg-white w-10/12 py-7 px-6 rounded-md shadow-xl md:ml-8 -mt-24 z-10 isolate">
            <h4 className="mb-2 font-bold leading-7 text-gray-700">
              {heading}
            </h4>
            <p className="mb-2 text-sm font-semibold text-gray-500">{date}</p>
            <div
              className="mb-4 text-base font-normal text-gray-600 line-clamp-3"
              dangerouslySetInnerHTML={{__html: blurb}}
            />
            <p className="font-medium">{client}</p>
            {tags && (
              <div className="flex gap-2.5 mt-4">
                {tags?.map(({id, title}) => (
                  <ButtonPrimary
                    key={`tag-${id}`}
                    label={title}
                    size="small"
                    style={{cursor: 'default'}}
                  />
                ))}
              </div>
            )}
          </motion.div>
        </div>
      </motion.article>
    </Link>
  );
};

export default Card;
